<div class="container-fluid min-vh-100 p-3 bg-light-subtle d-flex flex-column justify-content-between">
  <div class="row mb-5">
    <div class="col-md-7">
      <div class="d-flex gap-2 align-items-center">
        <!-- <div class="semi-circle"></div> -->
        <div>
          <!-- <p class="mb-0">{{ 'Community Platform' | translate }}</p> -->
        </div>
      </div>
    </div>
    <div class="col-md-5 text-end d-none d-md-block">
      <div class="d-flex align-items-center gap-3 justify-content-end">
        <!-- <div class="pointer"><i class="fa fa-language me-2"></i>En</div> -->
        <!-- <div class=""><a routerLink="">Signup</a></div>
        <div class=""><button class="btn btn-dark">Download App</button></div> -->
      </div>
    </div>
  </div>
  <div class="row align-items-center flex-column">
    <div class="col-md-3 mb-3 p-0" *ngIf="errorMessage">
      <ngb-alert [type]="'danger'" [dismissible]="false">{{ errorMessage }}</ngb-alert>
    </div>
    <div class="col-md-3 main-card p-4 py-3 rounded-3">
      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="row">
          <div class="col-md-12 text-center">
            <p class="fs-3 fw-bold">Account Login</p>
          </div>
          <div class="col-md-12 text-center">
            <p class="fs-6">Enter your details to get sign in to your account</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group mb-2">
              <label for="mobile" class="form-label">Mobile Number <sup class="text-danger">*</sup></label>
              <input type="text" name="mobile" onlyNumbers id="mobile" class="form-control" placeholder="890XXXXXXX"
                maxlength="10" formControlName="mobile">
              <div *ngIf="isSubmitted && loginForm.controls.mobile.errors?.required" class="text-danger mt-1">Mobile
                number is required.</div>
            </div>
            <div class="form-group mb-5">
              <label for="password" class="form-label">Password <sup class="text-danger">*</sup></label>
              <input type="password" name="mobile" id="password" class="form-control" placeholder="Password"
                formControlName="password">
              <div *ngIf="isSubmitted && loginForm.controls.password.errors?.required" class="text-danger mt-1">Password
                is required.</div>
            </div>
            <div class="form-group mb-2">
              <button class="btn btn-primary w-100" style="height: 2.1rem;" type="submit">
                <span *ngIf="!isLoading">Login</span>
                <app-mct-btn-loader *ngIf="isLoading"
                  class="d-flex justify-content-center align-items-center"></app-mct-btn-loader>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center">
      <!-- <p class="mb-0">Powered by <span> <img src="/assets/images/logo/footer-logo.jpg" alt="communityplatform"
            class="img-fluid rounded-2"></span> www.communityplatform.in | Copyright {{ today | date:'y'}} © bulu </p> -->
      <p class="mb-0">Powered by <span> <img src="/assets/images/logo/small-logo-2.jpg" alt="communityplatform"
            class="img-fluid rounded-2"></span> www.communityplatform.in | Copyright {{ today | date:'y'}} © bulu </p>
    </div>
  </div>
</div>